<template>
  <p class="mb-4">This is your author information shared among the publications that you have joined. Keep it accurate and professional.</p>
  <a-form
    :colon="false"
    layout="vertical"
    :model="values"
    ref="form"
    @finish="onFinish"
  >
    <div class="row">
      <div class="col-12 col-md-8 mb-4">
        <a-form-item
          label="Full Name"
          name="name"
          :rules="[
            { required: true, message: 'Name is required.' },
          ]"
        >
          <a-input
            v-model:value="values.name"
            :maxlength="32"
            name="name"
            placeholder="Your full name"
          />
        </a-form-item>
        <a-form-item
          label="Email Address"
          name="email"
          :rules="[
            { required: true, message: 'Email address is required.' },
            { type: 'email', message: 'Email address is invalid.' },
          ]"
        >
          <a-input
            v-model:value="values.email"
            name="email"
            placeholder="name@company.com"
            type="email"
          />
          <div v-if="$store.state.user.emailPending" class="text-danger">
            <a-tooltip title="Unverified" placement="top">
              <ExclamationCircleIcon class="icon me-2" />{{ $store.state.user.emailPending }}
            </a-tooltip>
            <a-button v-if="$store.state.user.verified" type="link" size="small" @click="onAbort">
              Abort Change
            </a-button>
          </div>
        </a-form-item>
        <a-form-item
          label="Personal Website"
          name="website"
          :rules="[
            { type: 'url', message: 'Website URL is invalid.' },
          ]"
        >
          <a-input
            v-model:value="values.website"
            name="website"
            placeholder="https://example.com"
            type="url"
          />
        </a-form-item>
      </div>
      <div class="col-12 col-md-4 mb-4">
        <a-form-item label="Picture">
          <AccountAvatar />
        </a-form-item>
      </div>
    </div>
    <h2 class="h5 mb-4">Links</h2>
    <a-form-item
      label="GitHub"
      name="github"
      :rules="[
        { min: 1, message: 'GitHub username must contain 1-39 characters.' },
      ]"
    >
      <a-input
        v-model:value="values.github"
        addonBefore="https://github.com/"
        :maxlength="39"
        name="github"
        placeholder="GitHub username"
      />
    </a-form-item>
    <a-form-item
      label="Instagram"
      name="instagram"
      :rules="[
        { min: 3, message: 'Instagram username must contain 3-100 characters.' },
      ]"
    >
      <a-input
        v-model:value="values.instagram"
        addonBefore="https://www.instagram.com/"
        :maxlength="100"
        name="instagram"
        placeholder="Instagram username"
      />
    </a-form-item>
    <a-form-item
      label="LinkedIn"
      name="linkedin"
      :rules="[
        { min: 3, message: 'LinkedIn custom URL must contain 3-100 characters.' },
      ]"
    >
      <a-input
        v-model:value="values.linkedin"
        addonBefore="https://www.linkedin.com/in/"
        :maxlength="100"
        name="linkedin"
        placeholder="LinkedIn custom short name"
      />
    </a-form-item>
    <a-form-item
      label="Twitter"
      name="twitter"
      :rules="[
        { min: 3, message: 'Twitter username must contain 3-15 characters.' },
      ]"
    >
      <a-input
        v-model:value="values.twitter"
        addonBefore="https://twitter.com/"
        :maxlength="15"
        name="twitter"
        placeholder="Twitter username"
      />
    </a-form-item>
    <a-button type="primary" html-type="submit" :loading="loading">
      Save
    </a-button>
  </a-form>
</template>

<script>
import { ExclamationCircleIcon } from '@heroicons/vue/24/outline'
import AccountAvatar from '@/components/Account/AccountAvatar'

export default {
  components: {
    AccountAvatar,
    ExclamationCircleIcon,
  },

  data() {
    return {
      loading: false,
      values: {
        name: this.$store.state.user.name,
        email: this.$store.state.user.email,
        website: this.$store.state.user.website,
        github: this.$store.state.user.profiles.github && this.$store.state.user.profiles.github.substring('https://github.com/'.length),
        instagram: this.$store.state.user.profiles.instagram && this.$store.state.user.profiles.instagram.substring('https://www.instagram.com/'.length),
        linkedin: this.$store.state.user.profiles.linkedin && this.$store.state.user.profiles.linkedin.substring('https://www.linkedin.com/in/'.length),
        twitter: this.$store.state.user.profiles.twitter && this.$store.state.user.profiles.twitter.substring('https://twitter.com/'.length),
      },
    }
  },

  methods: {
    async onFinish() {
      try {
        var values = await this.$refs.form.validateFields()

        this.loading = true

        var { data } = await this.$http.put(`/me`, values)

        this.$message.success('Profile has been saved.')
        this.$store.commit('user', data)
      } catch (err) {
        if (err.response) {
          this.$message.error(err.response.data.message)
        } else {
          console.error(err)
        }
      } finally {
        this.loading = false
      }
    },
    async onAbort() {
      try {
        var { data } = await this.$http.delete(`/me/email`)

        this.$message.success('Email address change has been aborted.')
        this.$store.commit('user', data)
      } catch (err) {
        if (err.response) {
          this.$message.error(err.response.data.message)
        } else {
          console.error(err)
        }
      }
    },
  },
}
</script>
