<template>
  <a-upload
    name="file"
    accept="image/*"
    listType="picture"
    :customRequest="onUpload"
    :showUploadList="false"
  >
    <img
      v-if="$store.state.user.image.resolutions['320']"
      class="rounded-circle"
      :src="$store.state.user.image.resolutions['320']"
      style="cursor: pointer; width: 100%;"
    >
    <img
      v-else
      class="rounded-circle"
      src="@/assets/images/placeholder-user.png"
      style="cursor: pointer; width: 100%;"
    >
  </a-upload>
  <a-button
    v-if="$store.state.user.image.hash"
    type="link"
    size="small"
    block
    :loading="loading"
    @click="onDelete"
  >
    Delete
  </a-button>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
    }
  },

  methods: {
    async onUpload(data) {
      try {
        this.loading = true

        var formData = new FormData()
        formData.append('file', data.file)

        var { data } = await this.$http.put(`/me`, formData)

        this.$store.commit('field', { image: data.image })
        this.$message.success('Profile picture has been saved.')
      } catch (err) {
        if (err.response) {
          this.$message.error(err.response.data.message)
        } else {
          this.$message.error(err.message)
        }
      } finally {
        this.loading = false
      }

      return true
    },
    onDelete() {
      this.$confirm({
        title: 'Delete Profile Picture',
        content: 'You’re about to delete your profile picture. This has immediate effects on the publications you’ve joined.',
        confirmLoading: this.loading,
        okText: 'Delete',
        okType: 'danger',
        onOk: async () => {
          await this.onConfirm()
        },
        onCancel: () => {},
      })
    },
    async onConfirm() {
      try {
        this.loading = true

        var { data } = await this.$http.delete(`/me/image`)

        this.$store.commit('field', { image: data.image })
        this.$message.success('Profile picture has been deleted.')
      } catch (err) {
        if (err.response) {
          this.$message.error(err.response.data.message)
        } else {
          this.$message.error(err.message)
        }
      } finally {
        this.loading = false
      }
    },
  },
}
</script>
