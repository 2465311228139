<template>
  <a-form
    :colon="false"
    layout="vertical"
    :model="values"
    ref="form"
    @finish="onFinish"
  >
    <a-form-item
      label="Current Password"
      name="passwordOld"
      :rules="[
        { required: true, message: 'Password is required.' },
        { min: 6, message: 'Password is too short.' },
      ]"
    >
      <a-input
        v-model:value="values.passwordOld"
        name="passwordOld"
        type="password"
      />
    </a-form-item>
    <a-form-item
      label="Choose a Secure Password"
      name="password"
      :rules="[
        { required: true, message: 'Password is required.' },
        { min: 6, message: 'Password is too short.' },
      ]"
    >
      <a-input
        v-model:value="values.password"
        name="password"
        type="password"
      />
    </a-form-item>
    <a-form-item
      label="Confirm Password"
      name="passwordConfirm"
      :rules="[
        { required: true, message: 'Password is required.' },
        { min: 6, message: 'Password is too short.' },
      ]"
    >
      <a-input
        v-model:value="values.passwordConfirm"
        name="passwordConfirm"
        type="password"
      />
    </a-form-item>
    <a-button type="primary" html-type="submit" :loading="loading">
      Save
    </a-button>
  </a-form>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      values: {
        passwordOld: null,
        password: null,
        passwordConfirm: null,
      },
    }
  },

  methods: {
    async onFinish() {
      try {
        var values = await this.$refs.form.validateFields()

        this.loading = true

        var { data } = await this.$http.put(`/me/password`, values)

        this.form.resetFields()
        this.$message.success('Password has been saved.')
      } catch (err) {
        if (err.response) {
          this.$message.error(err.response.data.message)
        } else {
          console.error(err)
        }
      } finally {
        this.loading = false
      }
    },
  },
}
</script>
